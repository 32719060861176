
export const DEFAULT_TYPE_VICKI = 'VICKI'
export const DEFAULT_TYPE_OKTA = 'OKTA'
export const UNACCEPTED_VICKI_ROLES = ['MECH_SHOP']
export const UNACCEPTED_OKTA_ROLES = ['MECH_SHOP']

export const INVALID_SELLER_NUMBER = "Invalid Number. Please enter the correct Seller Numbers"

export const ALL_AUCTIONS = 'All Auctions'
export const ALL_AUCTIONS_CODE = 'all_auctions_code'
export const ERROR_FETCHING_DATA = "Error Fetching Data"
export const ERROR_SAVING_DATA = "Error saving data. Please try again"
export const ERROR_USER_ID_INVALID = "The UserID entered does not exist. Please try again"
export const ERROR_EMAIL_ID_INVALID = "The Email ID entered is invalid or does not exist. Please try again"
export const ERROR_SOMETHING_WENT_WRONG = "Something went wrong. Please try again"
export const NOT_FOUND = `does not exist. Please try again`
export const REQUIRED = 'required'

export const GROUP_CODE_NOT_FOUND = "The Group Code entered is not found"

export const LABEL_CLIENT = "Client/ M.com"
export const LABEL_TEAM_MEMBER = "Team Member"
export const LABEL_AIM_EMPLOYEE_ID= 'AIM Employee ID'

export const DEFAULT_PRODUCT_LABEL = 'Smart Approve'
export const DEFAULT_PRODUCT = 'SMART_APPROVE'
export const DEFAULT_PRODUCT_VALUE = 'smart_approve'
export const SMART_INSPECT_VALUE = 'smart_inspect'
export const SMART_INSPECT_LABEL = 'Smart Inspect'
export const BOTH_PRODUCT_LABEL = 'Smart Approve and Smart Inspect'
export const BOTH_PRODUCT_VALUE = 'smart_approve,smart_inspect'
export const FIT_CONSOLE_VALUE = 'fit_console'
export const FIT_CONSOLE_LABEL = 'FIT Console'

export const DEFAULT_MY_INVENTORY = "My Inventory"
export const DEFAULT_ALL_INVENTORY = "All Inventory"


export const SERVICE_NOW_URL = "https://coxautotest.service-now.com/ess?id=sc_cat_item&sys_id=d3e73700db668450afe7e33648961907"

export const COLOR = {
    GRAY : '#ccc',
    DARK_GRAY: '#aaa',
    BLUE : '#0d66a1',
    RED  : '#cb2c2c',
    DARK_BLUE : '#003468',
    WHITE: '#fff',
    DARK: '#4a4a4a'
}

export const ERROR_STATUS_MESSAGE = {
    ACCESS_DENIED : {
        status: 'You Do Not Have Permission to View This Page',
        message: 'For assistance, please email or call Manheim Customer Care at 1-866--MANHEIM (626-4346), Mon-Sat 8am-12am and Sun 6pm-10pm ET.'
    },
    DEFAULT : {
        status: 'The server is temporarily unavailable',
        message: 'In the meantime, please click on reload to refresh the page' 
    }
}

export const DEFAULT_INFORMATION_ROLE = [
    {
        title: "View Only",
        content: "This user cannot make edits to any data"
    },
    {
        title: "Demo",
        content: "This user cannot submit any changes. This allows the user to approve work to see what it would do to the grade, but no changes can be saved for the vehicle"
    },
    {
        title: "Approver",
        content: "This user can approve reconditioning work, but cannot make edits to damage lines or vehicle details"
    },
    {
        title: "Client Rep",
        content: "This role had edit and delete guardrails applied on the web. On the device no guardrails are applied"
    },
    {
        title: "Editor",
        content: "(Coordinators) This role has access to edit all fields, with no guardrails applied"
    },
    {
        title: "Admin Editor",
        content: "(Managers) This is an advanced role that being able to edit notes added by other users and editing pricing of completed work"
    },
]

export const OPTION_FILTER_SELECT = {
    ROLE: [
        { value: "editor", label: "Editor" },
        { value: "viewer", label: "Viewer" },
        { value: "approver", label: "Approver" }
    ],
    PRODUCT: [
        { value: DEFAULT_PRODUCT_VALUE, label: DEFAULT_PRODUCT_LABEL },
        { value: SMART_INSPECT_VALUE, label: SMART_INSPECT_LABEL },
        { value: BOTH_PRODUCT_VALUE, label: BOTH_PRODUCT_LABEL },
        { value: FIT_CONSOLE_VALUE, label: FIT_CONSOLE_LABEL }
    ],
    LOCATION: [
        { value: "RAA", label: "Manheim Reverside - RAA" },
        { value: "RCCA", label: "Paris - RCCA" }
    ]
}

export const NO_OPTIONS = "No Options"
export const NO_ITEMS_FOUND = "No Items Found"
export const NO_DATA_AVAILABLE = "No Data Available"
export const ACTIVATED_USERS_LABEL = "ACTIVATED USERS"
export const DEACTIVATED_USERS_LABEL = "DEACTIVATED USERS"

export const FILTER_TYPE = {
    PRODUCT: "product",
    ROLE: "role",
    LOCATION: "location"
}

export const SORT_TYPE = {
    NAME: "name",
    ROLE: "role"
}

export const CONFIRM_MESSAGE = {
    DEACTIVATED: "Are you sure you want to deactivate",
    ACTIVATED: "Are you sure you want to activate"
}

export const DEFAULT_ICON_SIZE = {
    ACTION: "32"
}

export const PRODUCT_OPTIONS = [
    { value: DEFAULT_PRODUCT_VALUE, label: DEFAULT_PRODUCT_LABEL },
    { value: SMART_INSPECT_VALUE, label: SMART_INSPECT_LABEL },
    { value: FIT_CONSOLE_VALUE, label: FIT_CONSOLE_LABEL }
]
export const INVENTORY_OPTIONS = [
    { value: 1, label: DEFAULT_ALL_INVENTORY },
    { value: 0, label: DEFAULT_MY_INVENTORY }
]
export const ROLE_INFORMATION_HEADERS = ['Role', 'Description']

export const SERVICE_UNAVAILABLE = "Service Unavailable"

export const LIST_OPTIONS_OF_SEARCH_USER = ['Client/ M.com', 'Team Member']

export const SEARCH_BY_USER_ID = "Search by User ID"

export const SEARCH_BY_EMAIL_ADDRESS = "Search by Email Address"

export const ACTION_WITH_BTN_SEARCH = ["Search", "Filter"]

export const IS_NEW_SCHEMA = (process.env.REACT_APP_NEW_SCHEMA || 'true') === 'true'

export const COMPANY_LIST = [
    { value: '5 Star Vehicle Inspections', label: '5 Star Vehicle Inspections' },
    { value: 'AiM', label: 'AiM' },
    { value: 'AiM-Nissan', label: 'AiM-Nissan' },
    { value: 'AiM-VCI', label: 'AiM-VCI' },
    { value: 'APEX', label: 'APEX' },
    { value: 'ASI Auto Group', label: 'ASI Auto Group' },
    { value: 'Autobahn Inspection Services, LLC', label: 'Autobahn Inspection Services, LLC' },
    { value: 'Axis', label: 'Axis' },
    { value: 'Big Valley Auto Auction', label: 'Big Valley Auto Auction' },
    { value: 'Clear View Systems', label: 'Clear View Systems' },
    { value: 'Client', label: 'Client' },
    { value: 'Condition Pros Inspections LLC', label: 'Condition Pros Inspections LLC' },
    { value: 'Empire Inspection Services', label: 'Empire Inspection Services' },
    { value: 'ERS Inspections aka Inspection Pulling LLC', label: 'ERS Inspections aka Inspection Pulling LLC' },
    { value: 'Give Me the VIN', label: 'Give Me the VIN' },
    { value: 'Harris Group Inspections', label: 'Harris Group Inspections' },
    { value: 'Hopper Motorplex', label: 'Hopper Motorplex' },
    { value: 'Inspections Solutions', label: 'Inspections Solutions' },
    { value: 'ION Inspections', label: 'ION Inspections' },
    { value: 'Manheim', label: 'Manheim' },
    { value: 'Marick Group', label: 'Marick Group' },
    { value: 'McDaniels Contracting LLC', label: 'McDaniels Contracting LLC' },
    { value: 'Mid City McAndrew Motorcars', label: 'Mid City McAndrew Motorcars' },
    { value: 'MJ Trading Company', label: 'MJ Trading Company' },
    { value: 'Pride Dealer Services', label: 'Pride Dealer Services' },
    { value: 'Pro Financial', label: 'Pro Financial' },
    { value: 'Road Runner', label: 'Road Runner' },
    { value: 'Roadrunner Vehicle Inspectors', label: 'Roadrunner Vehicle Inspectors' },
    { value: 'SDR Inspections LLC', label: 'SDR Inspections LLC' },
    { value: 'TGNA', label: 'TGNA' },
    { value: 'Total Recon Nevada', label: 'Total Recon Nevada' },
    { value: 'Vehicle Inspection Services', label: 'Vehicle Inspection Services' },
    { value: 'WSP Auto Appraisals', label: 'WSP Auto Appraisals' }
]
